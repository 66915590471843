<template>
  <div
    ref="tagModal"
    class="modal fade"
    id="tagModal"
    tabindex="-1"
    aria-labelledby="titleTagModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content" v-if="$store.state.tagForm">
        <div class="modal-header">
          <h5 class="modal-title" id="titleTagModal">Тег</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="">
            <div class="mb-3">
              <label class="form-label">Название</label>
              <input
                type="text"
                v-model="$store.state.tagForm.name"
                class="form-control"
                placeholder="Название тега">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary me-3"
            @click="$socket.emit('tags', $store.state.tagForm); $store.commit('mergeStore', {tagForm: {}})">
            Сохранить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
export default {
  // computed: {
  //   tag () {
  //     return this.$store.state.tagForm
  //   }
  // },
  data () {
    return {
      // tag: {}
    }
  },
  mounted () {
    const tagModal = new Modal(this.$refs.tagModal)
    this.$store.commit('mergeStore', { tagModal })
  }
}
</script>

<style lang="css" scoped>
</style>
