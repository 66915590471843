<template>
  <div class="">
    <TagItem :tag="tag" v-for="tag in tags" :key="tag.id" />
    <TagModal />
  </div>
</template>

<script>
import TagItem from './TagItem'
import TagModal from './TagModal'
export default {
  name: 'Tags',
  components: {
    TagModal,
    TagItem
  },

  data () {
    return {
      id: null,
    }
  },

  computed: {
    tags () {
      if (this.id) {
        console.log('this.id', this.id)
        return this.$store.state.tag.tags
      } else {
        return this.$store.state.tags
      }
    }
  },

  async mounted () {
    try {
      if (this.$route.name === 'Tag') {
        this.id = this.$route.params.id
      }
    } catch (e) {
      console.error(e)
      // this.$toasted.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
