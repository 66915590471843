<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Теги
        </li>
      </ol>
    </nav>
    <div class="d-flex">
      <h3 class="mb-3">Теги</h3>
      <div class="ms-auto" v-if="$store.state.user.is_staff">
        <button
          ref="tagModal"
          class="btn btn-light"
          data-bs-toggle="modal"
          data-bs-target="#tagModal"
          @click="$store.commit('mergeStore', {tag: {}})">
          Добавить
        </button>
      </div>
    </div>
    <div class="card card-body pb-0">
      <TagList/>
    </div>
    <ChatsModal />
  </div>
</template>

<script>
import TagList from '../components/TagList'
import ChatsModal from '../components/ChatsModal'
export default {
  name: 'Tags',
  components: {
    TagList,
    ChatsModal,
    // TagModal
  },
  mounted () {
    this.$socket.emit('tags_list')
    this.$socket.emit('get_data', ['chats'])
  }
}
</script>

<style lang="scss" scoped>
</style>
