<template>
  <div
    ref="chatsModal"
    class="modal fade"
    id="chatsModal"
    tabindex="-1"
    aria-labelledby="titleChatsModal"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="titleTagModal">Чаты</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="$store.state.tag.id">
          <div class="d-flex flex-wrap">
            <ChatItem
              v-for="chat in $store.state.tag.chats"
              :key="chat.id"
              :chat="chat"
              class="bg-success"/>
          </div>

          <div class="d-flex flex-wrap">
            <ChatItem
              v-for="chat in getChats"
              :key="chat.id"
              :chat="chat"
              class="bg-secondary"/>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatItem from './ChatItem'
import { Modal } from 'bootstrap'
export default {
  components: {
    ChatItem,
  },
  // computed: {
  //   tag () {
  //     return this.$store.state.tagForm
  //   }
  // },
  data () {
    return {
      // tag: {}
    }
  },
  computed: {
    getChats () {
      return this.$store.state.chats.filter(c => !this.$store.state.tag.chat_ids.includes(c.id))
    }
  },
  mounted () {
    const chatsModal = new Modal(this.$refs.chatsModal)
    this.$store.commit('mergeStore', { chatsModal })
  }
}
</script>

<style lang="css" scoped>
</style>
