<template>
  <div class="mb-3">
    <div class="tag d-flex align-items-center flex-wrap">
      <button
        class="btn btn-secondary me-3"
        @click="selectTag(tag)">
        {{ tag.name }}
      </button>

      <div class="btn-group me-3 my-1 " v-if="$store.state.user.is_staff">
        <button
          class="btn btn-success btn-sm"
          @click="$store.commit('mergeStore', { tag })"
          data-bs-toggle="modal"
          data-bs-target="#chatsModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-dots" viewBox="0 0 16 16">
            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </button>
        <button
          class="btn btn-secondary btn-sm"
          @click="deleteTag(tag)">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
      </div>

      <div class="chat-list">
        <router-link
          v-for="chat in tag.chats"
          :key="chat.id"
          class="btn btn-success btn-sm me-1 my-1"
          :to="{name: 'Chat', params: {id: chat.id}}">
          {{ chat.name || chat.telegram_name }}
        </router-link>
      </div>

    </div>

    <div v-if="tag.tags.length" class="ps-3 pt-3">
      <TagItem :tag="child" v-for="child in tag.tags" :key="child.id" />
    </div>

  </div>
</template>

<script>
import TagItem from './TagItem'
export default {
  components: {
    TagItem,
  },
  props: {
    tag: Object
  },
  methods: {
    selectTag (tag) {
      this.$router.push({name: 'Tag', params: {id: tag.id}})
    },
    deleteTag (tag) {
      if (confirm('Удалить тег?')) {
        this.$socket.emit('delete_tag', {id: tag.id, tree_root_id: this.$route.params.id})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.tag{
  .btn-group{
    white-space: nowrap;
  }
  .btn-group, .chat-list{
    display: none;
  }
  &:hover{
    > .btn-group, .chat-list{
      display: block;
    }
  }
}
</style>
