<template>
  <div
    class="card mb-3 me-3 position-relative"
    @click="clickChat">
    <div class="card-body">
      <h5 class="card-title text-white">{{ chat.name || chat.origin_name || chat.telegram_name || 'Чат' }}</h5>
    </div>
    <div class="position-absolute top-0 end-0 badge bg-success fs-12">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chat: Object
  },
  methods: {
    clickChat () {
      if (this.$store.state.tag.chat_ids.includes(this.chat.id)) {
        this.$toast.error('Отключение чата от тега')
      } else {
        this.$toast.success('Добавление чата к тегу')
      }
      this.$socket.emit('tag_chat', {
        tag_id: this.$store.state.tag.id,
        chat_id: this.chat.id,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card:hover{
  cursor: pointer;
}
</style>
